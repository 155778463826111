<template>
  <div class="datapreview">
    <div class="itembox left-top">
      <div class="numpe">
        <div class="item currentyj">
          <div class="title">当期应收日常费用(元)</div>
          <div class="count">
            <span style="font-size:18px">￥</span>
            {{ statistics && statistics.Month.newMothDailyPrice || 0 }}
          </div>
        </div>
        <!-- <div class="item all">
          <div>
            <div class="title">基础费用欠费户数</div>
            <div class="count">{{ statistics && statistics.Month.rentArrears.userTotal || 0 }}</div>
          </div>
          <div style="margin-left:60px">
            <div class="title">基础费用欠费总金额</div>
            <div class="count">
              <span style="font-size:18px">￥</span>
              {{ statistics && statistics.Month.rentArrears.totalPrice || 0 }}
            </div>
          </div>
        </div> -->
        <!-- <div class="item charge"> -->
          <div class="item charge">
            <div class="title">当期应收户数</div>
            <div class="count">{{ statistics && statistics.Month.receivable.userTotal || 0 }}</div>
          </div>
          <div class="item charge">
            <div class="title">当期应收款金额(不包含当期日常费用)</div>
            <div class="count">
              <span style="font-size:18px">￥</span>
              {{ statistics && statistics.Month.receivable.totalPrice || 0 }}
            </div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="itembox right-top">
      <div class="title">商铺出租情况</div>
      <div id="echarts" ref="echarts"></div>
    </div>
    <div class="itembox left-bottom">
      <div class="title">重要消息通知</div>
      <div class="list" v-if="statistics">
        <div class="item" @click="goInfo(item.property_key)" v-for="item in statistics.propertyLog.propertyList" :key="item.property_log_id">
          <img src="../../assets/datapreview/ht.png" v-if="item.property_key == 50" alt />
          <img src="../../assets/datapreview/yc.png" v-if="item.property_key == 76" alt />
          <img src="../../assets/img/icon/tuikuan.png" v-if="item.property_key == 51" alt />
          <!-- <img src="../../assets/datapreview/water.png" v-else alt=""> -->
          <!-- <img src="../../assets/datapreview/water.png" alt="">
          <img src="../../assets/datapreview/water.png" alt="">
          <img src="../../assets/datapreview/water.png" alt="">-->
          <el-tooltip class="item" effect="dark" :content="item.property_content" placement="top">
            <div class="message"><div>{{ item.property_content }}</div></div>
          </el-tooltip>
          <div class="times">{{ item.create_at }}</div>
        </div>
      </div>
    </div>
    <div class="itembox right-bottom">
      <div class="title">巡检异常状况表</div>
      <div class="head">
        <div>上报时间</div>
        <div>异常问题</div>
        <div>处理情况</div>
      </div>
      <div class="list" v-if="statistics">
        <div class="item" v-for="item in statistics.taskLogList" :key="item.id">
          <div class="time">{{ item.create_at }}</div>
          <div class="yc">{{ item.inspection_abnormal }}</div>
          <div style="color: #FF3D3D;">未处理</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statistics: null
    }
  },
  created() {
    this.getStatistics();
  },
  mounted() {
    // let that = this
    // window.onresize = function () {
    //   that.$debounce(that.resize)
    // }
  },
  methods: {
    getStatistics() {
      this.$request.HttpGet('/index/list').then(res => {
        this.statistics = res.data;
        this.draw(res.data.MerchantsStall);
      });
    },

    draw(data) {
      let mychart = this.$echarts.init(this.$refs.echarts)
      mychart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '商铺出租情况',
            type: 'pie',
            radius: ['40%', '70%'],
            color: ['#0D55E5', '#3DDDFF'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: data.merchantsNotTotal || 0, name: '未出租数' },
              { value: data.merchantsSellTotal || 0, name: '已出租数' }
            ]
          }
        ]
      })
    },
    goInfo(type) {
      if (type == 40 || type == 9) {
        this.$router.push('/charge/merchantbill/basebill')
      } else if (type == 50) {
        this.$router.push('/contract/contractlist')
      } else if (type == 51){
        this.$router.push('/finance/refund')
      }
    },
    goUrl(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.datapreview {
  // width:calc(100vw - 173px);
  height: 100%;
  padding-top: 15px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 380px 380px;
  // background-color: red;
}
.itembox {
  flex-shrink: 0;
  box-sizing: border-box;
  &:nth-child(2n) {
    margin-left: 20px;
  }
  &:nth-child(n + 3) {
    margin-top: 20px;
  }
}
.left-top {
  // width: 990px;
  height: 380px;
  background: #ffffff;
  .dspht {
    width: 100%;
    height: 220px;
    background: #ffffff;
    border: 1px solid #dedede;
    padding: 19px 0 0 19px;
    box-sizing: border-box;
    box-sizing: border-box;
  }
  .title {
    width: 100%;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    span {
      font-weight: bolder;
    }
  }
  .list {
    width: 100%;
    margin-top: 66px;
    // padding-left: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      height: 100%;
      display: inline-flex;
      align-items: flex-end;
      cursor: pointer;
      .count {
        margin-bottom: 9px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      div {
        div {
          &:nth-child(2) {
            white-space: nowrap;
          }
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .itemImg {
      width: 40px;
      height: 40px;
      margin-right: 21px;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  //  户数
  .numpe {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #dedede;
    box-sizing: border-box;
    // margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 50px;
    box-sizing: border-box;
    white-space: nowrap;
    .item {
      .title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }
      .count {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-top: 17px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
    }
    // .all {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   flex: 1;
    //   padding: 50px;
    //   box-sizing: border-box;
    //   .count {
    //     color: #f8c405;
    //   }
    //   &::before {
    //     content: "";
    //     display: block;
    //     width: 1px;
    //     height: 40px;
    //     background: #dedede;
    //     // margin-right: 59px;
    //     // margin-left: 60px;
    //   }
    //   &::after {
    //     content: "";
    //     display: block;
    //     width: 1px;
    //     height: 40px;
    //     background: #dedede;
    //     // margin-left: 59px;
    //   }
    // }
    .charge {

      .title {
        width: 100px;
      }
      .count {
        color: #02ce80;
      }
    }
  }
}
.right-top {
  // width: 680px;
  height: 380px;
  background: #ffffff;
  border: 1px solid #dedede;
  .title {
    width: 100%;
    padding: 20px 0 0 21px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    // margin-bottom: 28px;
  }
  #echarts {
    width: 100%;
    height: 100%;
  }
}
.left-bottom {
  // width: 990px;
  height: 429px;
  background: #ffffff;
  border: 1px solid #dedede;
  .title {
    padding: 20px 0 0 21px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 28px;
  }
  .list {
    overflow-y: auto;
    height: calc(100% - 68px);
    overflow: auto;
    padding: 0 31px;
    box-sizing: border-box;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .item {
      display: flex;
      // align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      width: 100%;
      position: relative;
      .message {

        div{
           width: 70%;
           text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        vertical-align: middle;
      }
      .times {
        position: absolute;
        float: right;
        right: 0;
      }
    }
  }
}
.right-bottom {
  @extend .left-bottom;

  // width: 680px;
  height: 429px;
  background: #ffffff;
  border: 1px solid #dedede;
  padding: 0 20px;
  box-sizing: border-box;

  .head {
    height: 32px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 13px;
    div {
      &:nth-child(1) {
        width: 250px;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
  .title {
    padding-left: 0;
  }
  .list {
    padding: 0;
    height: calc(100% - 113px);
    .item {
      padding: 0 35px 0 20px;
      box-sizing: border-box;
      .time {
        width: 250px;
      }
      .yc {
        flex: 1;
        width: 250px;
      }
    }
  }
}
</style>
